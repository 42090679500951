// @ts-nocheck
export default {
    welcome: "Maligayang pagdating",
    email_address: "Email Address",
    email_or_phone: "Email Address o Numero ng Telepono",
    enter_your_email: "Ilagay ang Iyong Email",
    enter_your_email_or_phone:
        "Ilagay ang Iyong Email Address o Numero ng Telepono",
    phone_placeholder: "Numero ng telepono",
    password: "Password",
    confirm_password: "Kumpirmahin ang Password",
    log_in: "Mag log in",
    login: "Login",
    forgot_password: "Nakalimutan ang Password?",
    forgot_password_title: "Nakalimutan ang Password",
    keep_me_logged_in: "Panatilihin akong naka-log in",
    sign_in: "Mag-sign in",
    not_registered: "Hindi pa nakarehistro?",
    create_account: "Gumawa ng Account",
    sign_up: "Mag-sign up",
    first_name: "Pangalan",
    last_name: "Apelyido",
    dob_placeholder: "Petsa ng Kapanganakan (DD/MM/YYYY)",
    submit: "Ipasa",
    already_have_account: "May account na?",
    why_need_dob_title: "Bakit kailangan namin ito?",
    why_need_dob_text: `Sa paggamit ng Site na ito, kinakatawan mo, kinikilala, at sumasang-ayon na ikaw ay hindi bababa sa 14 taong gulang, o kung ikaw ay wala pang 18 taong gulang ngunit hindi bababa sa 14 taong gulang (isang "Minor"), na ginagamit mo ang Site na may pahintulot ng iyong magulang o legal na tagapag-alaga at natanggap mo ang pahintulot ng iyong magulang o legal na tagapag-alaga na gamitin ang Site at sumang-ayon sa mga Tuntunin nito. Kung ikaw ay isang magulang o legal na tagapag-alaga ng isang Minor, sumasang-ayon ka dito na itali ang Minor sa mga Tuntuning ito at ganap na bayaran at panatilihing walang pinsala ang Kumpanya kung ang Minor ay lumabag sa alinman sa mga Tuntuning ito. Kung ikaw ay hindi bababa sa 14 taong gulang, hindi mo maaaring gamitin ang Site anumang oras o sa anumang paraan o magsumite ng anumang impormasyon sa Kumpanya o sa Site. TANDAAN: May ilang pagkakataon ng software na ito na nangangailangan ng mga gumagamit na hindi bababa sa 14 taong gulang, hindi bababa sa 16 taong gulang, o hindi bababa sa 18 taong gulang. Kung ikaw ay tinanggihan ng pagpaparehistro pagkatapos ilagay ang iyong petsa ng kapanganakan, ito ay dahil ang partikular na portal na iyong nirehistro ay may isa sa mga kinakailangang ito.`,
    go_back: "Bumalik",
    email_verification: "Pagpapatunay ng Email",
    log_out: "Mag-log Out",
    resend_email_verification: "Muling Ipadala ang Pagpapatunay ng Email",
    resend_text_verification: "Muling Ipadala ang Pagpapatunay ng Text",
    send_email_verification_instead:
        "Ipadala ang Pagpapatunay ng Email sa halip",
    send_text_verification_instead: "Ipadala ang Pagpapatunay ng Text sa halip",
    reset_password: "I-reset ang Password",
    accept_invite: "Tanggapin ang Imbitasyon",
    my_hep: "Aking Home Exercise Plan",
    plan_created: "Nagawa ang Plano",
    remove_plan: "Tanggalin ang Plano",
    remove_plan_confirm_text:
        "Sigurado ka bang gusto mong tanggalin ang planong ito?",
    duplicate_plan_check_text:
        "Mayroon ka nang planong ito na nakatalaga. Sigurado ka bang gusto mong muling italaga ang planong ito?",
    duplicate_plan_modal: {
        yes_text: "Oo",
        no_text: "Pumunta sa Dashboard",
    },
    translation_disclaimer: {
        title: "Disclaimer sa Pagsasalin",
        subtitle: "Pakitandaan na ang aming mga pagsasalin ay pinapagana ng AI software, na tinatayang 98% tumpak. Upang matiyak ang malinaw at tumpak na komunikasyon, ipinapayo namin ang paghiling ng paglilinaw sa anumang mga pagsasalin na tila hindi malinaw o mali. Inirerekomenda din na gumamit ng simpleng wika at iwasan ang slang upang mabawasan ang mga posibleng hindi pagkakaunawaan.",
        accept_button_text: "NAUUNAWAAN KO AT SUMANG-AYON AKO NA GAMITIN SA AKING SARILING PANGANIB",
    },
    provider: "Tagapagbigay",
    self_help_text: "Edukasyon sa Sariling Tulong",
    show_all: "Ipakita Lahat",
    no_record: "Walang natagpuang rekord",
    no_heps: "Dito mo makikita ang iyong Mga Plano sa Pagbawi na ginawa pagkatapos na maitalaga sa iyo ng iyong tagapagbigay ng pangangalagang pangkalusugan o itinalaga sa sarili sa pamamagitan ng alinman sa aming mga tool sa tulong sa sarili na maaaring available sa iyo. Sa kasalukuyan ay wala kang nakatalaga.",
    what_to_do: "Ano ang gagawin",
    hold_time: "Oras ng Pagpigil",
    day_per_week: "Araw Bawat Linggo",
    equipment: "Kagamitan",
    sets: "Mga Set",
    reps: "Mga Ulit",
    weight: "Timbang",
    in_pounds: "Sa pounds",
    time_per_day: "Oras Bawat Araw",
    starting_position: "Simulang Posisyon",
    description: "Paglalarawan",
    what_you_feel: "Ano ang Dapat Mong Maramdaman",
    too_easy: "Napakadali",
    too_hard: "Napakahirap",
    just_right: "Tamang-tama",
    exercise: "Ehersisyo",
    education: "Edukasyon",
    back_to_list: "Bumalik sa listahan",
    about_this_exercise: "Ano ang palagay mo tungkol sa ehersisyong ito?",
    attachement: "Attachment",
    exercise_completed: "Natapos na ang Ehersisyo",
    no_details: "Walang magagamit na detalye.",
    hep_details: {
        hard_exercise_modal: {
            title: "Kailangan ng Pagsasaayos para sa Ehersisyo.",
            description_line1:
                "Ito ang pinakamadaling ehersisyo sa estratehiyang ito, kaya hindi kami makapagbibigay ng mas madali pa sa ngayon.",
            description_line2:
                "Kung nahihirapan ka sa anumang ehersisyo, may mga alalahanin, o hindi sigurado kung ano ang susunod na gagawin, huminto kaagad at kumonsulta sa isang Propesyonal sa Pangangalagang Pangkalusugan o sa iyong nakatalagang Tagapagbigay kung ang planong ito ng pagbawi ay inireseta.",
            btn_text: "Nabasa ko at nauunawaan ang mga rekomendasyong ito",
        },
    },
    flash_card: {
        yes_text: "Oo",
        no_text: "Hindi",
    },
    complete_strategy_modal: {
        title: "Congrats!",
        description: "Natapos mo na ang set ng mga ehersisyong ito.",
        btn_text: "Pumunta sa hep List",
    },
    talk_to_expert: {
        title: "Makipag-usap sa isang Eksperto",
        breadcrumb: "Makipag-usap sa isang Eksperto!",
        book_now: "Mag-book ngayon",
        powered_by: "pinapagana ng",
    },
    find_clinic: {
        title: "Maghanap ng Klinika",
        search: "Ilagay ang lokasyon",
        breadcrumb: "Maghanap ng Klinika",
        back_title: "Mga Lokasyon",
        result_text: "Mga Resulta ng Paghahanap",
        pagination_label: "Ipakita:",
        pagination_text: "Mga Entry",
        toggle_list_map_label: "Tingnan:",
        list_text: "Listahan",
        map_text: "Mapa",
        print_text: "i-print",
        not_found: "Walang Natagpuang Klinika",
    },
    messages: {
        title: "Mga Mensahe",
        new_message: "Bagong Mensahe",
        welcome: "Maligayang pagdating",
        you_are_signed_in_as: "Naka-sign in ka bilang",
        hi: "Hi",
        you_are_disconnected:
            "Ikaw ay kasalukuyang walang koneksyon sa internet.",
        practice: "Practice",
        provider: "Tagapagbigay",
        could_not_send_msg: "Hindi maipadala ang mensahe.",
        some_files_removed:
            "Ang ilang mga file na higit sa 100 MB ang laki ay tinanggal.",
        say_hello: "Mag-say Hello",
        hello_text: "Hello!",
        start_conversation: "Simulan ang iyong bagong pag-uusap",
        send: "Ipadala",
        textbox_placeholder: "Simulan ang pag-type...",
        search_placeholder: "Maghanap...",
        no_provider: "Walang nakatalagang tagapagbigay.",
        no_active_chat:
            "Wala kang aktibong chat. Simulan ang bagong chat sa pamamagitan ng pag-click sa bagong mensahe na button.",
    },
    header_messages: {
        title: "Mga Mensahe",
        view_all: "TINGNAN LAHAT",
        no_messages: "Walang mga mensahe na ipapakita Pa",
    },
    support: {
        title: "Suporta",
        tech_support_email: "Tech Support Email",
        other_question: "Iba pang mga Tanong",
        section1_title:
            "Suporta sa Klinikal (Mga Isyu na Kaugnay sa Pangangalagang Pangkalusugan)",
        section1_description:
            "Sa kaso ng isang nagbabanta sa buhay o medikal na emerhensiya, agad na i-dial ang 911. Para sa mga hindi emerhensiyang katanungan sa pangangalagang pangkalusugan, mangyaring makipag-ugnayan sa",
        section2_title: "Suporta sa Teknikal (Mga Isyu na Kaugnay sa Software)",
        section2_description:
            "Ang aming tech support ay magagamit mula 7:00 am hanggang 4:00 pm PST, Lunes hanggang Biyernes, maliban sa mga pangunahing holiday. Ang anumang mga pagsusumite na ginawa sa labas ng mga oras na ito ay tutugunan sa susunod na araw ng negosyo.",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "Plano sa Pag-iwas sa Pinsala",
        heading:
            "Wala ka bang kasalukuyang pinsala? Nagtatrabaho sa isang desk buong araw?",
        description:
            "Nagsisimula ng isang programa sa pagtakbo, o nais na pagbutihin ang iyong laro sa golf? Mayroon kaming mga ehersisyo na makakatulong na maiwasan ang sakit at pinsala pati na rin palakasin ang mga lugar na kailangan mong pagbutihin ang pagganap.",
        workplace_label: "Mga Plano sa Lugar ng Trabaho",
        sport_label: "Mga Plano sa Palakasan",
        speciality_label: "Mga Plano sa Espesyalidad",
        generalized_label: "Mga Pangkalahatang Plano",
        start_plan: "Simulan ang Plano",
    },
    footer: {
        copyright_text: "Everflex Plus Health | Lahat ng Karapatan ay Nakalaan",
        pat_no_text:
            "Ang mga produktong ito ay sakop ng Pat. No. US 11,587,687 at iba pang nakabinbing aplikasyon.",
        about: "Tungkol sa",
        security_statement: "Pahayag ng Seguridad",
        privacy_policy: "Patakaran sa Privacy",
        terms_conditions: "Mga Tuntunin at Kundisyon",
        contact: "Makipag-ugnayan",
        support: "Suporta",
        faqs: "FAQs",
        patent_information_text: "Impormasyon sa Patent",
    },
    my_profile: {
        head_title_text: "Profile",
        my_account_text: "Aking Account",
        tab: {
            account_information_text: "Impormasyon ng Account",
            communication_preferences_text: "Mga Kagustuhan sa Komunikasyon",
            change_password_text: "Palitan ang Password",
        },
        account_information_form: {
            page_heading_text: "Impormasyon ng Account",
            first_name_field: {
                label_text: "Pangalan",
                placeholder_text: "Ilagay ang Pangalan",
            },
            last_name_field: {
                label_text: "Apelyido",
                placeholder_text: "Ilagay ang Apelyido",
            },
            dob_field: {
                label_text: "Petsa ng Kapanganakan",
                placeholder_text: "Petsa ng Kapanganakan (MM/DD/YYYY)",
            },
            email_field: {
                label_text: "Email/Username",
                placeholder_text: "Ilagay ang Email/Username",
            },
            phone_number_field: {
                label_text: "Numero ng Telepono",
                placeholder_text: "Ilagay ang Numero ng Telepono",
            },
            otp_modal: {},
            unverified_email_text:
                "Ang iyong email/username ay hindi pa nabeberipika.",
            unverified_email_btn_text:
                "I-click dito upang muling ipadala ang verification email.",
            verification_link_sent_text:
                "Isang bagong verification link ang ipinadala sa iyong email address.",
            verification_link_not_sent:
                "Mangyaring magbigay ng email address upang ma-verify ito.",
            submit_btn_text: "Ipasa",
        },
        change_password_form: {
            page_heading_text: "Impormasyon sa Pag-login",
            current_password_field: {
                label_text: "Ang Iyong Kasalukuyang Password",
                placeholder_text: "Ilagay ang Kasalukuyang Password",
            },
            new_password_field: {
                label_text: "Bagong Password",
                placeholder_text: "Ilagay ang Bagong Password",
            },
            confirm_password_field: {
                label_text: "Kumpirmahin ang Password",
                placeholder_text: "Ilagay ang Kumpirmahin ang Password",
            },
            submit_btn_text: "Ipasa",
        },
        communication_preference_form: {
            page_heading_text: "Mga Kagustuhan sa Komunikasyon",
            table_headings: {
                setting_text: "Setting",
                opt_in_text: "Opt-In",
                opt_out_text: "Opt-Out",
            },
            table_body: {
                email_notification_text: "Mga Abiso sa Email",
                sms_notification_text: "Mga Abiso sa Text (SMS)",
            },
            submit_btn_text: "Ipasa",
        },
    },
    password_requirement_text:
        "Ang password ay dapat maglaman ng hindi bababa sa 8 karakter, naglalaman ng halo ng malalaking titik at maliliit na titik, mga numero, at mga simbolo.",
    language: {
        could_not_update: "Hindi ma-update ang wika.",
    },
    error_page: {
        go_back_text: "Bumalik sa Bahay",
        403: {
            title: "403: Ipinagbabawal",
            description:
                "Paumanhin, ipinagbabawal kang ma-access ang pahinang ito.",
        },
        404: {
            title: "404: Hindi Natagpuan ang Pahina",
            description:
                "Paumanhin, hindi matagpuan ang pahinang hinahanap mo.",
        },
        500: {
            title: "500: Error sa Server",
            description: "Whoops, may nangyaring mali sa aming mga server.",
        },
        503: {
            title: "503: Hindi Magagamit ang Serbisyo",
            description:
                "Paumanhin, kami ay nagsasagawa ng maintenance. Mangyaring bumalik mamaya.",
        },
    },
    identify_injury: {
        select: {
            front: "Harap",
            back: "Likod",
            heading: "Aking Pinsala",
            page_title: "Aking pinsala",
            okay: "Okay",
            tell_me: "sabihin mo sa akin",
            about_pain: "tungkol sa iyong sakit",
            answer_question:
                "Sagutin ang ilang mga tanong, at makatanggap ng isang personalized na serye ng mga ebidensyang nakabatay at mga pisikal na therapist na na-curate na mga ehersisyo na idinisenyo upang bigyan ka ng kapangyarihan na dahan-dahang mapawi ang sakit para sa 23 sa mga pinakakaraniwang iniulat na karamdaman. Kung hindi ka magkasya sa isang pattern ng pinsala, maaari kang mag-access ng self-help sa aming Generalized Plans, maghanap ng physical therapy clinic na malapit sa iyo, o magkaroon ng pagbisita sa isang live na provider online.",
            select_body_part:
                "Piliin ang isang bahagi ng katawan kung saan ka nagkakaroon ng mga problema:",
            next: "Susunod",
            body_part_not_found: "Hindi natagpuan ang bahagi ng katawan.",
        },
        show: {
            no_question_found: "Walang Natagpuang Tanong",
            next: "Susunod",
            heading: "Kilalanin ang Aking Pinsala",
            page_title: "Kilalanin ang Aking Pinsala",
            play_video_info:
                "I-play ang video upang marinig ang mas malalim na paliwanag ng tanong mula sa iyong virtual na pisikal na therapist.",
        },
        body_part: {
            heading: (bodyPart) => `Galugarin ang Pagsusuri sa ${bodyPart}`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `Hindi nakahanap ng ginhawa mula sa mga ehersisyo sa ${currentBodyPart}? Mag-redirect upang masuri at tugunan ang mga kaugnay na alalahanin sa ${newBodyPart}.`,
            explore_plans: "Galugarin ang mga Plano",
        },
        fallout: {
            heading:
                "Batay sa impormasyong ibinigay mo, hindi ka nahulog sa isa sa aming mga pattern ng pinsala.",
            sub_heading:
                "Inirerekomenda na makipag-usap ka sa isang lisensyadong pisikal na therapist upang matiyak na makakatanggap ka ng pinakamahusay na posibleng pangangalaga. Nag-aalok kami ng mga sumusunod na opsyon upang umangkop sa iyong mga pangangailangan:",
            talk_to_expert: "Makipag-usap sa isang eksperto",
            find_clinic: "Maghanap ng klinika",
        },
        generalized: {
            heading: "Galugarin ang mga Pangkalahatang Plano",
            sub_heading:
                "Hindi nahanap ang kailangan mo? Mag-redirect sa isang mas malawak na hanay ng mga opsyon upang pamahalaan ang iyong sakit o pahusayin ang iyong mga ehersisyo.",
            explore_plans: "Galugarin ang mga Plano",
            not_ready_to_speak_heading: "Hindi pa handang makipag-usap sa isang eksperto? Galugarin ang aming mga pangkalahatang plano.",
            not_ready_to_speak_sub_heading: "Mag-click sa ibaba para sa higit pang mga opsyon upang makatulong na pamahalaan ang iyong pananakit o pinsala",
        },
        hep: {
            heading: "Magaling 🎉",
            sub_heading:
                "Ang iyong mga tugon ay matagumpay na naitugma sa isang itinatag na pattern ng pinsala. Inatasan ka namin ng isang plano sa pagbawi na tumutugma sa mga resulta na ito.",
            get_started: "magsimula",
        },
        all_body_parts: {
            neck: "Leeg",
            shoulder: "Balikat",
            elbow_hand: "Siko at Kamay",
            elbow_arm: "Siko/Bisig",
            wrist_hand: "Pulso/Kamay",
            back: "Likod",
            hip: "Balakang",
            knee: "Tuhod",
            knee_leg: "Tuhod/Binti",
            ankle_foot: "Bukong-bukong at Paa",
        },
        something_went_wrong_self_help_logs:
            "May nangyaring mali sa pag-save ng iyong self log.",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "Tungkol",
            head_text: "Tungkol",
            title_text: "Tungkol",
        },
        security_statement: {
            breadcrumb_text: "Pahayag ng Seguridad",
            head_text: "Pahayag ng Seguridad",
            title_text: "Pahayag ng Seguridad",
        },
        privacy: {
            breadcrumb_text: "Patakaran sa Privacy",
            head_text: "Patakaran sa Privacy",
            title_text: "Patakaran sa Privacy",
        },
        terms: {
            breadcrumb_text: "Mga Tuntunin at Kundisyon",
            head_text: "Mga Tuntunin at Kundisyon",
            title_text: "Mga Tuntunin at Kundisyon",
        },
        faq: {
            breadcrumb_text: "Mga Madalas Itanong",
            head_text: "Mga Madalas Itanong",
            title_text: "Mga Madalas Itanong",
        },
        patent: {
            breadcrumb_text: "Impormasyon sa Patent",
            head_text: "Impormasyon sa Patent",
            title_text: "Impormasyon sa Patent",
        },
        no_data_text: "Walang Natagpuang Data!",
    },
    accept_terms: {
        i_agree_text: "I agree to terms and conditions as stated above.",
        yes_btn_text: "Yes",
        no_btn_text: "No",
    },
    hep_restart: {
        check_in: "Time to Check-in",
        completed: "Your Plan is Complete!",
        hey: "Hello,",
        complete_plan_desc: `We are thrilled to celebrate the awesome progress you've made over the past 21 days with you!`,
        take_a_moment: `Take a moment to reflect on how you're feeling so far.`,
        feeling_better: `Feeling better? Awesome!`,
        current_plan_desc: `You can click the COMPLETE MY CURRENT PLAN button below. Cheers to your health!`,
        resume_plan_desc: `To continue your plan and keep the momentum going, click the RESUME MY PLAN button below.`,
        clinic_desc: `If you haven't experienced much progress or need some extra support, we've got your back! It's okay to hit pause. We recommend chatting with healthcare professional who can provide personalized guidance.`,
        top_priority: `Your well-being is our top priority.`,
        support_you: `We're here to support you every step of the way.`,
        complete_plan_btn: `Complete my current plan`,
        resume_plan_btn: `Resume my current plan`,
        clinic_btn: "Find a healthcare professional near you",
        keep_moment: "Would you like to keep the momentum going?",
        is_completed_resume_plan: `Click the RESUME MY PLAN button below.`,
    },
    header_module: {
        notification_section: {
            popup_heading: "Mga Abiso",
            view_all_text: "Tingnan Lahat",
            no_data_text: "Walang mga abiso na ipapakita.",
        },
        select_preferred_language: "Piliin ang Nais na Wika",
        my_account: "Aking Account",
        sign_out: "Mag-sign Out",
        logout_go_back: "Mag-logout/Bumalik",
    },
    notification_center: {
        list: {
            page_title: "Listahan ng Sentro ng Abiso",
            page_heading: "Lahat ng Abiso",
            no_record: "Walang Natagpuang Abiso",
        },
        show: {
            page_title: "Ipakita ang Sentro ng Abiso",
            page_heading: "Detalye ng Abiso",
        },
        breadcrumb: {
            notification_text: "Lahat ng Abiso",
        },
        action: {
            view: "Tingnan",
            delete: "Tanggalin",
            delete_confirm:
                "Sigurado ka bang gusto mong tanggalin ang abisong ito?",
        },
    },
    general_error: "May nangyaring mali. Pakisubukang muli mamaya.",
    start_screen: {
        title: "Ilagay ang iyong email o numero ng telepono",
        heading: "Ilagay ang iyong email o numero ng telepono",
        email_placeholder: "Ilagay ang iyong email o numero ng telepono",
        btn_text: "Susunod",
        account_disabled_error:
            "Ang iyong account ay naka-disable, Mangyaring makipag-ugnayan sa suporta.",
        invite_sent:
            "Matagumpay na naipadala ang imbitasyon sa email, pakisuri ang iyong email.",
        invite_sent_by_text: "Naipadala ang imbitasyon sa text",
        send_invite_by_text:
            "Ipadala ang imbitasyon sa pamamagitan ng text sa halip",
        account_not_found: "Hindi natagpuan ang account!",
        choose_account: "Piliin ang Iyong Klinika upang Magpatuloy!",
    },
    invite_modal: {
        heading: "Nahanap Namin ang Iyong Account",
        sub_heading:
            "Ang iyong account ay nalikha na, ngunit hindi pa kumpleto ang proseso ng pag-signup. Pakisuri ang iyong email para sa isang imbitasyon upang kumpletuhin ang iyong pagpaparehistro, o humiling ng bagong imbitasyon kung kinakailangan.",
        btn_text: "Muling Ipadala ang Imbitasyon sa Email ng Account",
        btn_text_sms: "Muling Ipadala ang Imbitasyon sa SMS ng Account",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `Pinahihintulutan ko na ang mga sumusunod na komunikasyon mula sa ${clinicName} ay maihatid sa akin sa pamamagitan ng ibinigay na elektronikong paraan. Nauunawaan ko na ang ilang anyo ng elektronikong komunikasyon ay maaaring hindi ligtas, na nagdudulot ng panganib ng hindi tamang pagdiskubre sa mga hindi awtorisadong indibidwal.`,
        acceptance_text_2: `Handa akong tanggapin ang panganib na iyon, at hindi ko pananagutin ang klinika kung sakaling mangyari ang ganitong insidente.`,
        sms_notification_text: `Mga Paalala sa Text (SMS) - kapag naaangkop para sa iyong plano sa pagbawi at itinalaga ng iyong tagapagbigay`,
        email_notification_text: `Mga Abiso sa Email (mahalagang mga update, mga paalala ng iba pang impormasyon na may kaugnayan sa pangangalagang pangkalusugan, at paminsan-minsang mga mensahe sa marketing)`,
        accept_btn_text: "Tanggapin",
        submit_btn_text: "Ipasa",
        enter_phone: "ILAGAY ANG IYONG MOBILE PHONE NUMBER",
        default_timezone_text: (defaultTimezone) =>
            `Magpapadala kami sa iyo ng paalala sa text upang gawin ang iyong mga ehersisyo sa 6:00 PM lokal na oras. Mukhang ikaw ay nasa ${defaultTimezone} timezone, tama ba iyon?`,
        timezone_required: "Kinakailangan ang timezone.",
        saved: "Nai-save na ang mga kagustuhan.",
        no_btn_text: "Hindi",
        yes_btn_text: "Oo",
        select_timezone: "Piliin ang Timezone",
        select_your_timezone: "Piliin ang iyong timezone",
    },
    set_new_password: {
        heading: "ITAKDA ANG IYONG BAGONG PASSWORD",
        sub_heading:
            "Para sa pinahusay na seguridad, mangyaring ilagay ang iyong kasalukuyang password na ibinigay ng administrator at pagkatapos ay itakda ang iyong bagong password.",
        current_password_label: "Kasalukuyang Password",
        current_password_placeholder: "Ilagay ang Kasalukuyang Password",
        new_password_label: "Bagong Password",
        new_password_placeholder: "Ilagay ang Bagong Password",
        confirm_password_label: "Kumpirmahin ang bagong password",
        confirm_password_placeholder: "Kumpirmahin ang password",
        save_btn_text: "I-save ang Password",
    },
    hep_status: {
        completed: "Natapos",
        self_assigned: "Sariling Itinalaga",
        independent: "Independiyenteng Programa sa Bahay",
    },
    level: "Antas",
    sec: "Segundo",
    days: "Araw",
    repetitions: "Ulit",
    lbs: "Lbs",
    time: "Oras",
    allLanguages: {
        en: "Ingles",
        es: "Espanyol",
        fr: "Pranses",
        zh: "Tsino (Pinasimple)",
        "zh-TW": "Tsino (Tradisyonal)",
        tl: "Tagalog",
        vi: "Biyetnames",
        ar: "Arabe",
        ko: "Koreano",
        ru: "Ruso",
        hi: "Hindi",
        sw: "Swahili",
    },
};
