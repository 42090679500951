// @ts-nocheck
export default {
    welcome: "Welcome",
    email_address: "Email Address",
    email_or_phone: "Email Address or Phone Number",
    enter_your_email: "Enter Your Email",
    enter_your_email_or_phone: "Enter your Email Address or Phone Number",
    phone_placeholder: "Phone number",
    password: "Password",
    confirm_password: "Confirm Password",
    log_in: "Log in",
    login: "Login",
    forgot_password: "Forgot Password?",
    forgot_password_title: "Forgot Password",
    keep_me_logged_in: "Keep me logged in",
    sign_in: "Sign in",
    not_registered: "Not registered yet?",
    create_account: "Create an Account",
    sign_up: "Sign up",
    first_name: "First Name",
    last_name: "Last Name",
    dob_placeholder: "Date of Birth (DD/MM/YYYY)",
    submit: "Submit",
    already_have_account: "Already have an account?",
    why_need_dob_title: "Why do we need this?",
    why_need_dob_text: `Date of Birth (DOB) is an essential piece of information in the Everflex Plus platform because it allows accurate identification of patients, prevents errors in matching records, and ensures compliance with age-specific healthcare regulations.`,
    go_back: "Go Back",
    email_verification: "Email Verification",
    log_out: "Log Out",
    resend_email_verification: "Resend Verification Email",
    resend_text_verification: "Resend Verification Text",
    send_email_verification_instead: "Send Verification Email Instead",
    send_text_verification_instead: "Send Verification Text Instead",
    reset_password: "Reset Password",
    accept_invite: "Accept Invitation",
    my_hep: "My Home Exercise Plan",
    plan_created: "Plan Created",
    remove_plan: "Remove Plan",
    remove_plan_confirm_text: "Are you sure you want to remove this plan?",
    duplicate_plan_check_text:
        "You already have this plan assigned. Are you sure you want to reassign this plan?",
    duplicate_plan_modal: {
        yes_text: "Yes",
        no_text: "Go To Dashboard",
    },
    translation_disclaimer: {
        title: "Translation Disclaimer",
        subtitle: "Please note that our translations are powered by AI software, which is approximately 98% accurate. To ensure clear and accurate communication, we advise requesting clarification on any translations that seem unclear or incorrect. It is also recommended to use simple language and avoid slang to minimize potential misunderstandings.",
        accept_button_text: "I UNDERSTAND AND AGREE TO USE AT MY OWN RISK",
    },
    provider: "Provider",
    self_help_text: "Self-Help Education",
    show_all: "Show All",
    no_record: "No record found",
    no_heps: "Here is where you will find your Recovery Plans that were created after they have been assigned to you by your healthcare provider or self-assigned through any of our self-help tools that may be available to you. Currently you have none assigned.",
    what_to_do: "What to do",
    hold_time: "Hold Time",
    day_per_week: "Day Per Week",
    equipment: "Equipment",
    sets: "Sets",
    reps: "Reps",
    weight: "Weight",
    in_pounds: "In pounds",
    time_per_day: "Time Per Day",
    starting_position: "Starting Position",
    description: "Description",
    what_you_feel: "What You Should Feel",
    too_easy: "Too Easy",
    too_hard: "Too Hard",
    just_right: "Just Right",
    exercise: "Exercise",
    education: "Education",
    back_to_list: "Back to list",
    about_this_exercise: "What do you think about this exercise?",
    attachement: "Attachment",
    exercise_completed: "Exercise Completed",
    no_details: "No details available.",
    hep_details: {
        hard_exercise_modal: {
            title: "Adjustment Needed for Exercise.",
            description_line1:
                "This is the easiest exercise in this strategy, so we cannot provide an easier one at this time.",
            description_line2:
                "If you find any exercise too difficult, have concerns, or are unsure of what to do next, stop immediately and consult a Healthcare Professional or your assigned Provider if this recovery plan was prescribed.",
            btn_text: "I have read and understand these recommendations",
        },
    },
    flash_card: {
        yes_text: "Yes",
        no_text: "No",
    },
    complete_strategy_modal: {
        title: "Congrats!",
        description: "You have completed this set of exercises.",
        btn_text: "Go to hep List",
    },
    talk_to_expert: {
        title: "Talk to an Expert",
        breadcrumb: "Talk to an Expert!",
        book_now: "Book now",
        powered_by: "powered by",
    },
    find_clinic: {
        title: "Find a Clinic",
        search: "Enter location",
        breadcrumb: "Find a Clinic",
        back_title: "Locations",
        result_text: "Search Results",
        pagination_label: "Show:",
        pagination_text: "Entries",
        toggle_list_map_label: "View:",
        list_text: "List",
        map_text: "Map",
        print_text: "print",
        not_found: "No Clinics Found",
    },
    messages: {
        title: "Messages",
        new_message: "New Message",
        welcome: "Welcome",
        you_are_signed_in_as: "You are signed in as",
        hi: "Hi",
        you_are_disconnected:
            "You are currently disconnected from the internet.",
        practice: "Practice",
        provider: "Provider",
        could_not_send_msg: "Could not send the message.",
        some_files_removed: "Some files above 100 MB size were removed.",
        say_hello: "Say Hello",
        hello_text: "Hello!",
        start_conversation: "Start your new conversation",
        send: "Send",
        textbox_placeholder: "Start typing...",
        search_placeholder: "Search...",
        no_provider: "No provider assigned.",
        no_active_chat:
            "You have no active chats. Start a new chat by clicking the new message button.",
    },
    header_messages: {
        title: "Messages",
        view_all: "VIEW ALL",
        no_messages: "No messages to show Yet",
    },
    support: {
        title: "Support",
        tech_support_email: "Tech Support Email",
        other_question: "Other Questions",
        section1_title: "Clinical Support (Healthcare Related Issues)",
        section1_description:
            "In case of a life-threatening or medical emergency, dial 911 immediately. For non-emergency healthcare inquiries, please reach out to",
        section2_title: "Technical Support (Software Related Issues)",
        section2_description:
            "Our tech support is available from 7:00 am to 4:00 pm PST, Monday to Friday, excluding major holidays. Any submissions made outside these hours will be addressed on the next business day.",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "Injury Prevention Plan",
        heading: "Don't currently have an injury? Work at a desk all day?",
        description:
            "Starting a running program, or want to improve your golf game? We have exercises that will help prevent pain and injury as well as strengthen areas you need to improve performance.",
        workplace_label: "Workplace Plans",
        sport_label: "Sport Plans",
        speciality_label: "Speciality Plans",
        generalized_label: "Generalized Plans",
        start_plan: "Start Plan",
    },
    footer: {
        copyright_text: "Everflex Plus Health | All Rights Reserved",
        pat_no_text:
            "These products are covered by Pat. No. US 11,587,687 and other pending applications.",
        about: "About",
        security_statement: "Security Statement",
        privacy_policy: "Privacy Policy",
        terms_conditions: "Terms & Conditions",
        contact: "Contact",
        support: "Support",
        faqs: "FAQs",
        patent_information_text: "Patent Information",
    },
    my_profile: {
        head_title_text: "Profile",
        my_account_text: "My Account",
        tab: {
            account_information_text: "Account Information",
            communication_preferences_text: "Communication preferences",
            change_password_text: "Change Password",
        },
        account_information_form: {
            page_heading_text: "Account Information",
            first_name_field: {
                label_text: "First Name",
                placeholder_text: "Enter First Name",
            },
            last_name_field: {
                label_text: "Last Name",
                placeholder_text: "Enter Last Name",
            },
            dob_field: {
                label_text: "Date of Birth",
                placeholder_text: "Date of Birth (MM/DD/YYYY)",
            },
            email_field: {
                label_text: "Email/Username",
                placeholder_text: "Enter Email/Username",
            },
            phone_number_field: {
                label_text: "Phone Number",
                placeholder_text: "Enter Phone Number",
            },
            otp_modal: {},
            unverified_email_text: "Your email/username is unverified.",
            unverified_email_btn_text:
                "Click here to resend the verification email.",
            verification_link_sent_text:
                "A new verification link has been sent to your email address.",
            verification_link_not_sent:
                "Please provide an email address to verify it.",
            submit_btn_text: "Submit",
        },
        change_password_form: {
            page_heading_text: "Login Information",
            current_password_field: {
                label_text: "Your Current Password",
                placeholder_text: "Enter Current Password",
            },
            new_password_field: {
                label_text: "New Password",
                placeholder_text: "Enter New Password",
            },
            confirm_password_field: {
                label_text: "Confirm Password",
                placeholder_text: "Enter Confirm Password",
            },
            submit_btn_text: "Submit",
        },
        communication_preference_form: {
            page_heading_text: "Communication Preferences",
            table_headings: {
                setting_text: "Setting",
                opt_in_text: "Opt-In",
                opt_out_text: "Opt-Out",
            },
            table_body: {
                email_notification_text: "Email Notifications",
                sms_notification_text: "Text (SMS) Notifications",
            },
            submit_btn_text: "Submit",
        },
    },
    password_requirement_text:
        "Password must contain a minimum of 8 characters, including at least 1 number and 1 special character.",
    language: {
        could_not_update: "Could not update language.",
    },
    error_page: {
        go_back_text: "Go Back Home",
        403: {
            title: "403: Forbidden",
            description: "Sorry, you are forbidden from accessing this page.",
        },
        404: {
            title: "404: Page Not Found",
            description:
                "Sorry, the page you are looking for could not be found.",
        },
        500: {
            title: "500: Server Error",
            description: "Whoops, something went wrong on our servers.",
        },
        503: {
            title: "503: Service Unavailable",
            description:
                "Sorry, we are doing some maintenance. Please check back soon.",
        },
    },

    identify_injury: {
        select: {
            front: "Front",
            back: "Back",
            heading: "My Injury",
            page_title: "My injury",
            okay: "Okay",
            tell_me: "tell me",
            about_pain: "about your pain",
            answer_question:
                "Answer some questions, and receive a personalized series of evidence-based and physical therapists-curated exercises designed to empower you to gently alleviate the pain for 23 of the most commonly reported ailments. If you do not fit into an injury pattern, you can access self-help with our Generalized Plans, find a physical therapy clinic near you, or have a visit with a live provider online.",
            select_body_part:
                "Select one body part where you are having problems:",
            next: "Next",
            body_part_not_found: "Body part not found.",
        },
        show: {
            no_question_found: "No Question found",
            next: "Next",
            heading: "Identify My Injury",
            page_title: "Identify My Injury",
            play_video_info:
                "Play the video to hear a more in-depth explanation of the question from your virtual physical therapist.",
        },
        body_part: {
            heading: (bodyPart) => `Explore ${bodyPart} Assessment`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `Didn't find relief from ${currentBodyPart} exercises? Redirect to assess and address related ${newBodyPart} concerns.`,
            explore_plans: "Explore Plans",
        },
        fallout: {
            heading:
                "Based on the information you provided, your condition does not align with our typical injury patterns.",
            sub_heading:
                "We recommend consulting a healthcare professional to ensure you receive the best possible care. To assist you further, we offer the following options tailored to your needs.",
            talk_to_expert: "Talk to an expert",
            find_clinic: "Find a clinic",
        },
        generalized: {
            heading: "Explore Generalized Plans",
            sub_heading:
                "Didn't find what you need? Redirect to a broader range of options to manage your pain or enhance your exercises.",
            explore_plans: "Explore Plans",
            not_ready_to_speak_heading: "Not ready to speak with an expert? Explore our General Plans.",
            not_ready_to_speak_sub_heading: "Click below for more options.",
        },
        hep: {
            heading: "Well Done 🎉",
            sub_heading:
                "Your responses have been successfully matched to an established injury pattern. We have assigned you a recovery plan that matches these results.",
            get_started: "get started",
        },
        all_body_parts: {
            neck: "Neck",
            shoulder: "Shoulder",
            elbow_hand: "Elbow & Hand",
            elbow_arm: "Elbow / Arm",
            wrist_hand: "Wrist / Hand",
            back: "Back",
            hip: "Hip",
            knee: "Knee",
            knee_leg: "Knee / Leg",
            ankle_foot: "Ankle & Foot",
        },
        something_went_wrong_self_help_logs:
            "Something went wrong in saving your self log.",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "About",
            head_text: "About",
            title_text: "About",
        },
        security_statement: {
            breadcrumb_text: "Security Statement",
            head_text: "Security Statement",
            title_text: "Security Statement",
        },
        privacy: {
            breadcrumb_text: "Privacy Policy",
            head_text: "Privacy Policy",
            title_text: "Privacy Policy",
        },
        terms: {
            breadcrumb_text: "Terms & Conditions",
            head_text: "Terms & Conditions",
            title_text: "Terms & Conditions",
        },
        faq: {
            breadcrumb_text: "Frequently Asked Questions",
            head_text: "Frequently Asked Questions",
            title_text: "Frequently Asked Questions",
        },
        patent: {
            breadcrumb_text: "Patent Information",
            head_text: "Patent Information",
            title_text: "Patent Information",
        },
        no_data_text: "No Data Found!",
    },
    accept_terms: {
        i_agree_text: "I agree to terms and conditions as stated above.",
        yes_btn_text: "Yes",
        no_btn_text: "No",
    },
    hep_restart: {
        check_in: "Time to Check-in",
        completed: "Your Plan is Complete!",
        hey: "Hello,",
        complete_plan_desc: `We are thrilled to celebrate the awesome progress you've made over the past 21 days with you!`,
        take_a_moment: `Take a moment to reflect on how you're feeling so far.`,
        feeling_better: `Feeling better? Awesome!`,
        current_plan_desc: `You can click the COMPLETE MY CURRENT PLAN button below. Cheers to your health!`,
        resume_plan_desc: `To continue your plan and keep the momentum going, click the RESUME MY PLAN button below.`,
        clinic_desc: `If you haven't experienced much progress or need some extra support, we've got your back! It's okay to hit pause. We recommend chatting with healthcare professional who can provide personalized guidance.`,
        top_priority: `Your well-being is our top priority.`,
        support_you: `We're here to support you every step of the way.`,
        complete_plan_btn: `Complete my current plan`,
        resume_plan_btn: `Resume my current plan`,
        clinic_btn: "Find a healthcare professional near you",
        keep_moment: "Would you like to keep the momentum going?",
        is_completed_resume_plan: `Click the RESUME MY PLAN button below.`,
    },
    header_module: {
        notification_section: {
            popup_heading: "Notifications",
            view_all_text: "View All",
            no_data_text: "There are no notifications to display.",
        },
        select_preferred_language: "Select Preferred Language",
        my_account: "My Account",
        sign_out: "Sign Out",
        logout_go_back: "Logout/Go Back",
    },
    notification_center: {
        list: {
            page_title: "Notification Center List",
            page_heading: "All Notifications",
            no_record: "No Notifications Found",
        },
        show: {
            page_title: "Notification Center Show",
            page_heading: "Notification Detail",
        },
        breadcrumb: {
            notification_text: "All Notifications",
        },
        action: {
            view: "View",
            delete: "Delete",
            delete_confirm:
                "Are you sure you want to delete this notification?",
        },
    },
    general_error: "Something went wrong. Please try again later.",
    start_screen: {
        title: "Enter your email or phone number",
        heading: "Enter your email or phone number",
        email_placeholder: "Enter your email or phone number",
        btn_text: "Next",
        account_disabled_error:
            "Your account is disabled, Please contact support.",
        invite_sent: "Invite email sent successfully, please check your email.",
        invite_sent_by_text: "Invite text sent",
        send_invite_by_text: "Send invite by text instead",
        account_not_found: "Account not found!",
        choose_account: "Choose Your Clinic to Continue!",
    },
    invite_modal: {
        heading: "We Found Your Account",
        sub_heading:
            "Your account has been created, but the signup process is incomplete. Please check your email for an invitation to complete your registration, or request a new invitation if necessary.",
        btn_text: "Resend Account Invite Email",
        btn_text_sms: "Resend Account Invite SMS",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `I authorize that the following communications from ${clinicName} be delivered to me by the provided electronic means. I understand that some forms of electronic communications may not be secure, creating a risk of improper disclosure to unauthorized individuals.`,
        acceptance_text_2: `I am willing to accept that risk, and will not hold the practice responsible should such an incident occur.`,
        sms_notification_text: `Text (SMS) Notifications Reminders - when appropriate for your recovery plan and assigned by your provider`,
        email_notification_text: `Email Notifications (important updates, reminders of other healthcare-related information, and occasional marketing messages)`,
        accept_btn_text: "Accept",
        submit_btn_text: "Submit",
        enter_phone: "ENTER YOUR MOBILE PHONE NUMBER",
        default_timezone_text: (defaultTimezone) =>
            `We will send you a text reminder to do your exercises at 6:00 PM local time. It appears you are in ${defaultTimezone} timezone, is that correct?`,
        timezone_required: "Timezone is required.",
        saved: "Preference have been saved.",
        no_btn_text: "No",
        yes_btn_text: "Yes",
        select_timezone: "Select Timezone",
        select_your_timezone: "Select your timezone",
    },
    set_new_password: {
        heading: "SET YOUR NEW PASSWORD",
        sub_heading:
            "For enhanced security, please enter your current password provided by the administrator and then set your new password.",
        current_password_label: "Current Password",
        current_password_placeholder: "Enter Current Password",
        new_password_label: "New Password",
        new_password_placeholder: "Enter New Password",
        confirm_password_label: "Confirm New Password",
        confirm_password_placeholder: "Confirm New Password",
        save_btn_text: "Save Password",
    },
    hep_status: {
        completed: "Completed",
        self_assigned: "Self-Assigned",
        independent: "Independent Home Program",
    },
    level: "Level",
    sec: "Sec",
    days: "Days",
    repetitions: "Repetitions",
    lbs: "Lbs",
    time: "Time",
    allLanguages: {
        en: "English",
        es: "Spanish",
        fr: "French",
        zh: "Chinese (Simplified)",
        "zh-TW": "Chinese (Traditional)",
        tl: "Tagalog",
        vi: "Vietnamese",
        ar: "Arabic",
        ko: "Korean",
        ru: "Russian",
        hi: "Hindi",
        sw: "Swahili",
    },
};
